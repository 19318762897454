import React from "react";

const NotFoundPage = () => {
  return (
    <>
      <p>notfound</p>
    </>
  );
};

export default NotFoundPage;
